<template>
  <div>
    <v-dialog v-model="show" width="400">
      <v-card>
        <v-card-title class="blue white--text">Confirmación para reubicar</v-card-title>
        <v-card-text class="pt-3 black--text">
          <v-row>
            <v-col cols="12">
              <p>
                Al reubicar a un estudiante con pagos pendientes, se anulará la
                inscripción al curso y se
                <span class="font-weight-bold">
                  eliminarán los registros de los pagos
                </span>
                asociados al curso.
              </p>
              <span>¿Deseas continuar?</span>
            </v-col>
            <v-col cols="12" class="d-flex justify-end">
                <v-btn text @click="closeModal()">Cancelar</v-btn>
                <v-btn text color="red darken-3" @click="updateUbication()">Reubicar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      form: {}
    };
  },

  methods: {
    openModal(form) {
      this.show = true;
      this.form = form;
    },
    closeModal() {
      this.show = false;
    },
    updateUbication() {
      this.$emit("updateUbication", this.form);
    },
  },
};
</script>

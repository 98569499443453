<template>
  <div>
    <v-banner class="pb-0">
      <div class="d-flex justify-space-between pr-4">
        <p class="text-h5 font-weight-medium mb-0 d-flex align-center">
          Estudiantes
        </p>
        <v-spacer></v-spacer>
        <v-switch
          class="float-right mt-5 pt-0 pb-0 mr-5"
          v-model="formatoCsv"
          @change="convertCsv()"
          label="CSV Moodle"
        ></v-switch>
        <v-btn
          color="primary"
          elevation="0"
          class="mt-3 pt-0 pb-0"
          href="/register"
          target="_blank"
        >
          <v-icon left light> mdi-plus </v-icon>
          <span class="d-none d-sm-flex">Agregar Estudiante</span>
          <span class="d-flex d-sm-none">Agregar</span>
        </v-btn>
      </div>
    </v-banner>

    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="black--text">
              <v-icon left color="naranja">mdi-filter</v-icon>
              Filtros
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="p-0">
                <v-col cols="12" md="4">
                  <v-autocomplete
                    label="Estado"
                    :items="student_status"
                    item-text="text"
                    item-value="value"
                    required
                    outlined
                    hide-details
                    v-model="data.filters.status"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    label="Modalidad de estudio"
                    :items="study_modalities"
                    item-text="text"
                    item-value="value"
                    required
                    outlined
                    hide-details
                    v-model="data.filters.study_modality"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-data-table
              v-if="searchInArray(actions, 'Ver')"
              :headers="data.headers"
              :items="filterData"
              :item-per-page="5"
              :search="search"
              :loading="data.load"
              loading-text="Cargando datos..."
              fixed-header
              single-expand
              item-key="id_student"
              :expanded.sync="data.expanded"
              show-expand
              @click:row="fillExpandedRow"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="text-h5">
                    Listado de Estudiantes
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    label="Buscar..."
                    v-model="search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <vue-json-to-csv
                            :json-data="csvData"
                            :labels="labelsData"
                            csv-title="Lista De Estudiantes"
                          >
                            <v-icon>mdi-file-excel</v-icon>
                            Descargar CSV
                          </vue-json-to-csv>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </template>
              <!-- Inicia: Contenido de la tabla -->
              <template v-slot:[`item.age`]="{ item }">
                {{ item.age | calculateAge }}
              </template>
              <template v-slot:[`item.program`]="{ item }">
                <span v-if="item.program"
                  >{{ item.program }} - {{ item.language }}</span
                >
                <span v-else>N/A</span>
              </template>
              <template v-slot:[`item.course`]="{ item }">
                <div class="text-nowrap">
                  <span v-if="item.course">
                    {{ `${item.program} ${item.course}` }}
                  </span>
                  <span v-else>N/A</span>
                </div>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <div class="text-nowrap">
                  {{ item.status | getStatus }}
                </div>
              </template>
              <template v-slot:[`item.study_modality`]="{ item }">
                <div class="text-nowrap">
                  {{ item.study_modality | getModality }}
                </div>
              </template>
              <!-- Finaliza: Contenido de la tabla -->

              <!-- Contenido desplegable con las acciones-->
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="py-4">
                  <!-- Editar estudiante-->
                  <v-btn
                    v-if="searchInArray(actions, 'Editar')"
                    color="amber"
                    class="white--text mx-1"
                    @click="
                      selectStudent(item);
                      modalUpdate();
                    "
                  >
                    <v-icon color="white" left> mdi-pencil </v-icon>
                    Editar
                  </v-btn>
                  <!-- Responsables -->
                  <v-btn
                    v-if="searchInArray(actions, 'Editar')"
                    color="green"
                    class="white--text mx-1"
                    @click="
                      selectStudent(item);
                      modalResponsable();
                    "
                  >
                    <v-icon color="white" left> mdi-account-supervisor </v-icon>
                    Responsables
                  </v-btn>
                  <!-- Descuento -->
                  <v-btn
                    color="orange"
                    class="white--text mx-1"
                    @click="
                      selectStudent(item);
                      modalDiscount();
                    "
                  >
                    <v-icon color="white" left> mdi-ticket-percent </v-icon>
                    Descuento </v-btn
                  >
                  <!-- Expediente -->
                  <v-btn
                    v-if="searchInArray(actions, 'Ver')"
                    color="blue-grey"
                    class="white--text mx-1"
                    @click="
                      selectStudent(item);
                      modalRecord();
                    "
                  >
                    <v-icon color="white" left> mdi-file </v-icon>
                    Expediente
                  </v-btn>
                  <!-- Texto credenciales -->
                  <v-btn
                    color="info"
                    class="white--text mx-1"
                    @click="copyMailText(item)"
                  >
                    <v-icon color="white" left>
                      mdi-clipboard-text-outline
                    </v-icon>
                    Copiar texto de envío de credenciales
                  </v-btn>
                  <!-- Eliminar -->
                  <v-btn
                    v-if="searchInArray(actions, 'Eliminar')"
                    color="red"
                    class="white--text mx-1"
                    @click="
                      selectStudent(item);
                      modalDelete();
                    "
                  >
                    <v-icon color="white" left> mdi-delete </v-icon>
                    Eliminar
                  </v-btn>
                  <!-- Activar/desactivar -->
                  <v-btn
                    v-if="searchInArray(actions, 'Activar/Desactivar')"
                    :color="
                      item.status != 2
                        ? 'light-blue darken-3'
                        : 'blue-grey darken-1'
                    "
                    class="white--text mx-1"
                    @click="
                      selectStudent(item);
                      changeStatus();
                    "
                  >
                    <v-icon color="white" left>
                      {{
                        item.status != 2
                          ? "mdi-eye-outline"
                          : "mdi-eye-off-outline"
                      }}
                    </v-icon>
                    {{ item.status != 2 ? "Desactivar" : "Activar" }}
                  </v-btn>
                </td>
              </template>

              <!-- Reser data -->
              <template>
                <v-btn color="primary" @click="getStudents()"> Recargar </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Modals -->
    <RecordStudent ref="mdlRecord" :student="slcStudent" />
    <UpdateStudent
      ref="mdlUpdate"
      :student="slcStudent"
      :clean="cleanUpdate"
      @openConfirm="showConfirmModal"
    />
    <ResponsableStudent
      ref="mdlResponsable"
      :student="slcStudent"
      :clean="cleanUpdate"
    />
    <!-- Dialog Delete -->
    <v-dialog v-model="dialogDelete" max-width="300px">
      <v-card>
        <v-card-title class="headline">Advertencia</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center">
          <p class="mb-0 mt-4">¿Está seguro de eliminar este elemento?</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="closeDelete()">
            Cancelar
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteStudentConfirm()">
            Eliminar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog Discount -->
    <v-dialog v-model="dialogDiscount" max-width="350px">
      <v-card>
        <v-card-title class="headline">Descuento</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center">
          <v-container>
            <v-row>
              <v-col>
                <v-switch
                  v-model="slcStudent.colaborator_discount"
                  label="¿Desea aplicar el descuento?"
                  color="primary"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="slcStudent.percent_discount"
                  label="Porcentaje de descuento"
                  prepend-icon=""
                  prepend-inner-icon="mdi-ticket-percent"
                  required
                  outlined
                  type="number"
                  min="0"
                  max="100"
                  hide-details
                  :disabled="!slcStudent.colaborator_discount"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="closeDiscount()">
            Cancelar
          </v-btn>
          <v-btn color="primary darken-1" text @click="applyDiscount()">
            Aplicar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmReubication
      ref="confirmReubication"
      @updateUbication="relocateStudent"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueJsonToCsv from "vue-json-to-csv";
import RecordStudent from "./RecordStudent";
import UpdateStudent from "./UpdateStudent";
import ResponsableStudent from "./ResponsableStudent";
import ConfirmReubication from "./ConfirmReubication";

export default {
  name: "Student",
  components: {
    RecordStudent,
    UpdateStudent,
    ResponsableStudent,
    VueJsonToCsv,
    ConfirmReubication,
  },
  data: () => ({
    data: {
      load: true,
      headers: [
        { text: "Carnet", value: "code" },
        { text: "Nombres", value: "first_name" },
        { text: "Apellidos", value: "last_name" },
        { text: "Edad", value: "age" },
        { text: "Correo Personal", value: "email" },
        { text: "Celular", value: "phone" },
        { text: "Fecha de Inscripción", value: "created_at_format" },
        { text: "Último Curso", value: "course" },
        { text: "Estado", value: "status" },
        { text: "Acciones", value: "data-table-expand" }
      ],
      data: [],
      filters: {},
      expanded: [],
    },
    slcStudent: {},
    dialogDelete: false,
    dialogDiscount: false,
    isLoading: false,
    formatoCsv: false,
    search: "",
    student_status: [
      { value: 1, text: "Activos" },
      { value: 2, text: "Inactivos" },
      { value: 3, text: "Nuevo Ingreso (Examen)" },
      { value: 4, text: "Nuevo Ingreso" },
    ],
    study_modalities: [
      { value: 0, text: "Virtual" },
      { value: 1, text: "Presencial" },
    ],
  }),
  mounted() {
    this.getCurrentActions("Estudiantes");
    this.getStudents();
  },
  methods: {
    //actions
    ...mapActions({
      getCurrentActions: "getCurrentActions",
      openSnackbar: "Snackbar/openSnackbar",
    }),

    getStudents: async function () {
      this.data.load = true;
      await this.$http
        .get(this.api + "student/all")
        .then(({ data }) => {
          if (data.length > 0) {
            data.forEach((item) => {
              item.age = item.birth_date;
            });
          }
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },

    selectStudent(student) {
      this.slcStudent = student;
    },
    fillExpandedRow(item) {
      let content;
      // Si se ha seleccionado otro estudiante, se reemplaza el contenido
      if (this.data.expanded.length !== 0 && this.data.expanded[0].id_student != item.id_student) {
        content = item;
      }
      // Si no hay nada se guarda
      if (this.data.expanded.length === 0) {
        content = item;
      }
      
      // Reinicia la fila desplegable 
      this.data.expanded = [];

      // Si hay algo por guardar
      if (content) {
        this.data.expanded.push(content);
      }
      
    },
    modalUpdate() {
      this.$refs.mdlUpdate.changeModal();
    },

    cleanUpdate() {
      this.slcStudent = {};
      this.getStudents();
    },

    modalDelete() {
      this.dialogDelete = true;
    },

    modalDiscount() {
      this.dialogDiscount = true;
    },

    modalRecord() {
      this.$refs.mdlRecord.changeModal();
    },

    cleanResponsable() {
      this.newStudent = {};
      this.getStudents();
    },

    modalResponsable() {
      this.$refs.mdlResponsable.changeModal();
    },

    deleteStudentConfirm() {
      this.isLoading = true;
      this.$http
        .delete(this.api + "student/" + this.slcStudent.id_student)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.closeDelete();
            this.cleanUpdate();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
          this.closeDelete();
        });
    },

    changeStatus() {
      this.$http
        .get(this.api + "student/status/" + this.slcStudent.id_student)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.cleanUpdate();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        });
    },

    applyDiscount: function () {
      this.isLoading = true;
      this.slcStudent.colaborator_discount = this.slcStudent
        .colaborator_discount
        ? 1
        : 0;
      if (
        this.slcStudent.colaborator_discount &&
        this.slcStudent.percent_discount == 0
      ) {
        this.openSnackbar({
          type: "danger",
          message: "El porcentaje debe ser mayor a 0",
        });
      } else {
        this.$http
          .post(
            this.api + "student/discount/" + this.slcStudent.id_student,
            this.toFormData(this.slcStudent)
          )
          .then(({ data }) => {
            this.openSnackbar({
              type: data.response ? "success" : "warning",
              message: data.message,
            });
            if (data.response) {
              this.closeDiscount();
            }
          })
          .catch((err) => {
            this.openSnackbar({
              type: "danger",
              message: "Error al realizar la petición",
            });
          })
          .then(() => {
            this.isLoading = false;
          });
      }
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.slcStudent = {};
      });
    },

    closeDiscount() {
      this.dialogDiscount = false;
      this.$nextTick(() => {
        this.slcStudent = {};
      });
    },

    convertCsv() {
      if (this.formatoCsv) {
        this.data.headers = [
          { text: "username", value: "email_student" },
          { text: "firstname", value: "first_name" },
          { text: "lastname", value: "last_name" },
          { text: "email", value: "email" },
          { text: "password", value: "password_fake" },
          { text: "Último Curso", value: "course" },
        ];
      } else {
        this.data.headers = [
          { text: "Carnet", value: "code" },
          { text: "Nombres", value: "first_name" },
          { text: "Apellidos", value: "last_name" },
          { text: "Edad", value: "age" },
          { text: "Fecha de nacimiento", value: "birth_date" },
          { text: "Correo Electrónico", value: "email" },
          { text: "Correo Institucional", value: "email_student" },
          { text: "Celular", value: "phone" },
          { text: "Último Curso", value: "course" },
          { text: "Estado", value: "status" },
          { text: "Acciones", value: "actions", sortable: false },
        ];
      }
    },
    async copyMailText(student) {
      const text = `Estimado padre o madre de familia. Se ha Generado su usuario para el ingreso a nuestra plataforma de registro académico sei.ricaldone.edu.sv en la cual podrá realizar el pago del curso. Las credenciales son:
usuario: ${student.email_student}
Contraseña: ${student.password_fake}
Para una mejor comprensión sobre como cancelar en Linea, ver el siguiente tutorial: https://youtu.be/XYWphdgiXic?t=43
`;
      await this.copyToClipboard(text);
    },
    showConfirmModal(ubicationForm) {
      this.$refs.confirmReubication.openModal(ubicationForm);
    },
    relocateStudent(form) {
      this.$http
        .post(this.api + "student/ubication_student", this.toFormData(form))
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });

          this.$refs.mdlUpdate.changeModal();
          this.cleanUpdate();
          this.$refs.confirmReubication.closeModal();
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  filters: {
    calculateAge: function (value) {
      let birthday = new Date(value);
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs);
      let age = Math.abs(ageDate.getUTCFullYear() - 1970);
      return age;
    },
    getStatus: function (value) {
      switch (parseInt(value)) {
        case 1:
          return "Activo";
        case 2:
          return "Desactivado";
        case 3:
          return "Nuevo Ingreso (Examen)";
        case 4:
          return "Nuevo Ingreso";

        default:
          return "?";
      }
    },
    getModality: function (value) {
      switch (parseInt(value)) {
        case 0:
          return "Virtual";
          break;
        case 1:
          return "Presencial";
          break;

        default:
          return "Sin seleccionar";
          break;
      }
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      actions: "getCurrentActions",
    }),
    labelsData() {
      let headers = {};
      let localHeaders = this.data.headers;
      if (localHeaders.length > 0) {
        localHeaders.forEach((header) => {
          headers[header.value] = { title: header.text };
        });
      }
      return headers;
    },
    csvData() {
      let data = [];
      //de esta manera le quito la reactividad a la variables
      let localDataJSON = JSON.stringify(this.data.data);
      let localdata = JSON.parse(localDataJSON);
      //transformando data
      if (localdata.length > 0) {
        localdata.forEach((item) => {
          item.age = "";
          //cambios por key
          for (var [key, value] of Object.entries(item)) {
            if (key == "age") {
              item.age = this.$options.filters.calculateAge(item.birth_date);
            }
            if (key == "course") {
              if (item.program != null && item.course != null) {
                item.course = item.program + " " + item.course;
              } else {
                item.course = "N/A";
              }
            }
            if (key == "status") {
              item.status = this.$options.filters.getStatus(item.status);
            }
          }
          data.push(item);
        });
      }
      return data;
    },
    filterData() {
      //quitandole la reactividad
      let localDataJSON = JSON.stringify(this.data.data);
      let data = JSON.parse(localDataJSON);
      //descontruccion
      let { status, study_modality } = this.data.filters;
      //filtros
      if (status != undefined) {
        data = data.filter((item) => item.status == status);
      }
      if (study_modality != undefined) {
        data = data.filter((item) => item.study_modality == study_modality);
      }
      return data;
    },
  },
};
</script>

<style>
.v-input--switch .v-input__control .v-input__slot {
  margin-bottom: 0px !important;
}
</style>

<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="800"
      fullscreen
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark primary>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Responsables del Estudiante
            {{ `${student.first_name} ${student.last_name} (${student.code})` }}
          </v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="data.headers"
                :items="data.data"
                :item-per-page="5"
                :search="search"
                :loading="data.load"
                loading-text="Cargando datos..."
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title class="text-h5">
                      Resposable del estudiante
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field
                      label="Buscar..."
                      v-model="search"
                      append-icon="mdi-magnify"
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item>
                          <v-list-item-title>
                            <v-icon>mdi-file-excel</v-icon>
                            Descargar CSV
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-toolbar>
                </template>
                <!-- Actions -->
                <template v-slot:[`item.actions`]="{ item }">
                  <template>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          @click="
                            selectResponsable(item);
                            modalUpdate();
                          "
                          color="amber"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                  </template>
                  <template>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="ml-5"
                          @click="
                            selectResponsable(item);
                            modalDelete();
                          "
                          color="red"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                  </template>
                </template>

                <!-- Reser data -->
                <template>
                  <v-btn color="primary" @click="getResponsables()">
                    Reiniciar
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- Modal -->
    <UpdateResponsable
      ref="mdlUpdate"
      :responsable="slcResponsable"
      :clean="cleanUpdate"
    ></UpdateResponsable>
    <!-- Dialog Delete -->
    <v-dialog v-model="dialogDelete" max-width="450px">
      <v-card>
        <v-card-title class="headline">Advertencia</v-card-title>
        <v-card-text class="text-center">
          <p class="mb-0">
            ¿Está seguro de eliminar este elemento?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="closeDelete()">
            Cancelar
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteResponsableConfirm()">
            Eliminar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UpdateResponsable from "./UpdateResponsable";

export default {
  name: "ResponsableOfResponsable",
  components: {
    UpdateResponsable,
  },
  data() {
    return {
      data: {
        load: false,
        headers: [
          { text: "Nombres", value: "first_name" },
          { text: "Apellidos", value: "last_name" },
          { text: "Celular", value: "cellphone" },
          { text: "Teléfono", value: "phone" },
          { text: "Correo", value: "email" },
          { text: "Parentesco", value: "kin" },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        data: [],
      },
      dialog: false,
      slcResponsable: {},
      search: "",
      dialogDelete: false,
    };
  },
  props: { student: Object },
  mounted() {},
  methods: {
    //actions
    ...mapActions({
      getCurrentActions: "getCurrentActions",
      openSnackbar: "Snackbar/openSnackbar",
    }),
    getResponsables: async function() {
      this.data.data = [];
      this.data.load = true;
      await this.$http
        .get(this.api + "responsable/all/" + this.student.id_student)
        .then(({ data }) => {
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },

    changeModal: function() {
      this.dialog = !this.dialog;
    },

    selectResponsable(responsable) {
      this.slcResponsable = responsable;
    },

    modalUpdate() {
      this.$refs.mdlUpdate.changeModal();
    },

    cleanUpdate() {
      this.slcResponsable = {};
      this.getResponsables();
    },

    modalDelete() {
      this.dialogDelete = true;
    },

    deleteResponsableConfirm() {
      this.isLoading = true;
      this.$http
        .delete(this.api + "responsable/" + this.slcResponsable.id_responsable)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.closeDelete();
            this.cleanUpdate();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
          this.closeDelete();
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.slcStudent = {};
      });
    },
  },
  watch: {
    student(val) {
      this.getResponsables();
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      apiAdmision: "getAdmisionApi",
      apiAis: "getAisApi",
      apiCore: "getCoreApi",
    }),
  },
};
</script>

<style></style>

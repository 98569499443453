<template>
  <v-dialog v-model="dialog" width="800" scrollable>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span class="headline"> Modificar Estudiante </span>
        <v-alert
          color="red"
          dark
          dense
          icon="mdi-alert"
          prominent
          v-if="hasPendingPayments"
          >Tiene pagos pendientes.</v-alert
        >
      </v-card-title>
      <v-card-subtitle class="pb-1 pt-1">
        {{ `${student.first_name} ${student.last_name} (${student.code})` }}
      </v-card-subtitle>
      <v-divider />
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <h3 class="black--text">Ubicación del estudiante</h3>
              <!-- Alerta de evaluación diagnóstica-->
              <div v-if="existEvaluation">
                <div>
                  <v-alert dense outlined type="info" class="mt-2 mb-0">
                    <p class="mb-1">
                      <span>Comentario: </span>{{ evaluationDiagnostic.suggested_level }}
                    </p>
                    <span
                      >Prueba realizada por:
                      <span class="font-weight-bold">
                        {{ evaluationDiagnostic.realized_by }} - {{ evaluationDiagnostic.diagnostic_date }}
                      </span>
                      
                    </span>
                  </v-alert>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-autocomplete
                label="Idiomas"
                :items="ubication.languages"
                item-text="language"
                item-value="id_language"
                outlined
                hide-details
                v-model="ubicationSelected.id_language"
                @change="getProgramsUb"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-autocomplete
                label="Programa del Curso"
                :items="ubication.programs"
                item-text="program"
                item-value="id_program"
                outlined
                hide-details
                v-model="ubicationSelected.id_program"
                @change="getCoursesUb"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-autocomplete
                label="Curso"
                :items="ubication.courses"
                item-text="course"
                item-value="id_course"
                outlined
                hide-details
                v-model="ubicationSelected.id_course"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.course }} - [{{ data.item.cycle }}]
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.course }} - [{{ data.item.cycle }}]
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn
                color="primary"
                @click="saveUbication()"
                :disabled="!completeInfo"
                v-if="!hasPendingPayments"
                :loading="isLoading"
              >
                <v-icon left>mdi-map-marker</v-icon>
                Ubicar Estudiante
              </v-btn>
              <v-btn
                color="blue"
                class="white--text"
                :disabled="!completeInfo"
                v-else
                @click="showConfirmModal()"
                :loading="isLoading"
              >
                <v-icon left>mdi-map-marker-path</v-icon>
                Reubicar Estudiante
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="my-5" />

          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                label="Nombres"
                required
                outlined
                hide-details
                v-model="student.first_name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                label="Apellidos"
                required
                outlined
                hide-details
                v-model="student.last_name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="student.birth_date"
                    label="Fecha de Nacimiento"
                    required
                    outlined
                    prepend-icon=""
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="student.birth_date"
                  :max="minAge"
                  :min="maxAge"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-select
                label="Género"
                :items="genders"
                item-text="gender"
                item-value="id_gender"
                required
                outlined
                hide-details
                v-model="student.id_gender"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                label="Teléfono"
                required
                outlined
                hide-details
                v-model="student.phone"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                label="Correo electrónico"
                required
                outlined
                hide-details
                v-model="student.email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-autocomplete
                label="Departmento"
                :items="departments"
                item-text="department"
                item-value="id_department"
                required
                outlined
                hide-details
                v-model="student.id_department"
                @change="getMunicipalities"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-autocomplete
                label="Municipio"
                :items="municipalities"
                item-text="municipality"
                item-value="id_municipality"
                required
                outlined
                hide-details
                v-model="student.id_municipality"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                label="Dirección"
                required
                outlined
                rows="1"
                auto-grow
                hide-details
                v-model="student.address"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-divider class="my-5" />
          <v-row>
            <v-col cols="12" sm="12">
              <h3 class="black--text">Información Academica</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-autocomplete
                label="Departmento de la institución educativa"
                :items="sch_departments"
                item-text="department"
                item-value="id_department"
                required
                outlined
                hide-details
                v-model="student.id_department_school"
                @change="getSchMunicipalities"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-autocomplete
                label="Municipio de la institución educativa"
                :items="sch_municipalities"
                item-text="municipality"
                item-value="id_municipality"
                required
                outlined
                hide-details
                v-model="student.id_municipality_school"
                @change="getSchools"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-autocomplete
                label="Colegio/Escuela Académica Actual"
                :items="schools"
                item-text="school"
                item-value="id_school"
                required
                outlined
                hide-details
                v-model="student.id_school"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-autocomplete
                label="Grado Académico"
                :items="degrees"
                item-text="degree"
                item-value="id_degree"
                required
                outlined
                hide-details
                v-model="student.id_degree"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-divider class="my-5" />
          <v-row>
            <v-col cols="12" sm="12">
              <h3 class="black--text">Modalidad de estudio</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-select
                label="Modalidad de estudio"
                :items="study_modalities"
                item-text="modality"
                item-value="value"
                required
                outlined
                hide-details
                v-model="student.study_modality"
              ></v-select>
            </v-col>
          </v-row>

          <v-divider class="my-5" />
          <v-row>
            <v-col cols="12" sm="12" class="pb-0">
              <h3 class="black--text">Credenciales de acceso</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <p class="black--text mb-0">
                <span class="font-weight-bold">Usuario: </span>
                <span> {{ student.email_student }} </span>
              </p>
            </v-col>
            <v-col cols="12" sm="3">
              <p class="black--text mb-0">
                <span class="font-weight-bold">Contraseña SEI: </span>
                <span> {{ student.password_fake_sistema }} </span>
              </p>
            </v-col>
            <v-col cols="12" sm="3">
              <p class="black--text mb-0">
                <span class="font-weight-bold">Contraseña Moodle: </span>
                <span> {{ student.password_fake }} </span>
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray darken-1" text @click="closeModal()">
          Cancelar
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="update()"
          :loading="isLoading"
        >
          Modificar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  name: "UpdateStudent",
  data() {
    return {
      dialog: false,
      isLoading: false,
      date: null,
      menu: false,
      minAge: null,
      maxAge: null,
      //ubication
      ubication: {
        languages: [],
        programs: [],
        courses: [],
      },
      ubicationSelected: {
        id_language: null,
        id_program: null,
        id_course: null,
      },
      evaluationDiagnostic: {},
      //data
      genders: [],
      departments: [],
      municipalities: [],
      sch_departments: [],
      sch_municipalities: [],
      schools: [],
      degrees: [],
      study_modalities: [
        { value: 0, modality: "Virtual" },
        { value: 1, modality: "Presencial" },
      ],
    };
  },
  props: {
    student: Object,
    clean: { type: Function },
  },
  mounted() {
    this.getGenders();
    this.getDegrees();
    this.getDepartments();

    this.getLanguagesUb();
    //limites edades
    let min_date = moment().subtract(7, "years").format("Y-MM-DD");
    let max_date = moment().subtract(90, "years").format("Y-MM-DD");
    this.minAge = min_date;
    this.maxAge = max_date;
  },
  methods: {
    //actions
    ...mapActions({
      getCurrentActions: "getCurrentActions",
      openSnackbar: "Snackbar/openSnackbar",
    }),
    //ubicacion
    getLanguagesUb: function () {
      this.$http.get(this.api + "language/all").then(({ data }) => {
        this.ubication.languages = data;
      });
    },
    getProgramsUb: function () {
      let id = this.ubicationSelected.id_language;
      this.$http.get(this.api + "program/language/" + id).then(({ data }) => {
        this.ubication.programs = data;
      });
      this.ubicationSelected.id_program = null;
      this.ubicationSelected.id_course = null;
    },
    getCoursesUb: function () {
      let id = this.ubicationSelected.id_program;
      this.$http.get(this.api + "course/program/" + id).then(({ data }) => {
        this.ubication.courses = data;
      });
      this.ubicationSelected.id_course = null;
    },
/*     checkDiagnosticResult() {
      this.evaluationDiagnostic.isLoading = true;
      axios
        .get(
          `${this.api}diagnostic-test-results/one/${this.student.id_student}`
        )
        .then(({ data }) => {
          if (Object.keys(data).length !== 0) {
            this.evaluationDiagnostic.user_name = "Nombre";
            this.evaluationDiagnostic.suggested_level = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";
            this.evaluationDiagnostic.found = true;
            return;
          }
          // No tiene evaluación para mostrar
          this.evaluationDiagnostic.found = false;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: err,
          });
          this.evaluationDiagnostic.found = false;
        })
        .finally(() => {
          this.evaluationDiagnostic.isLoading = false;
        })
    }, */
    saveUbication: function () {
      this.isLoading = true;
      this.ubicationSelected.id_student = this.student.id_student;
      this.$http
        .post(
          this.api + "student/ubication_student",
          this.toFormData(this.ubicationSelected)
        )
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });

          if (data.response) {
            this.closeModal();
            this.clean();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //funcionalidad
    getGenders: function () {
      this.$http.get(this.apiCore + "gender_all").then(({ data }) => {
        this.genders = data;
      });
    },
    getDegrees: function () {
      this.$http.get(this.apiAis + "degree/all").then(({ data }) => {
        this.degrees = data;
      });
    },
    getDepartments: function () {
      this.$http.get(this.apiAdmision + "department/all").then(({ data }) => {
        this.departments = data;
        this.sch_departments = data;
      });
    },
    getMunicipalities: function () {
      let id = this.student.id_department;
      this.$http
        .get(this.apiAdmision + "municipality/department/" + id)
        .then(({ data }) => {
          this.municipalities = data;
        });
    },
    getSchMunicipalities: function () {
      let id = this.student.id_department_school;
      this.$http
        .get(this.apiAdmision + "municipality/department/" + id)
        .then(({ data }) => {
          this.sch_municipalities = data;
        });
    },
    getSchools: function () {
      let id = this.student.id_municipality_school;
      this.$http
        .get(this.apiAdmision + "school/municipality/" + id)
        .then(({ data }) => {
          this.schools = data;
        });
    },
    update: function () {
      this.isLoading = true;

      this.$http
        .post(
          this.api + "student/" + this.student.id_student,
          this.toFormData(this.student)
        )
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.closeModal();
            this.clean();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    changeModal() {
      this.dialog = !this.dialog;
    },
    closeModal() {
      this.dialog = false;
      this.ubicationSelected = {
        id_language: null,
        id_program: null,
        id_course: null,
      }
    },
    showConfirmModal() {
      this.ubicationSelected.id_student = this.student.id_student;
      this.$emit("openConfirm", this.ubicationSelected);
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    student(val) {
      this.getMunicipalities();
      this.getSchMunicipalities();
      this.getSchools();
      if (this.student?.diagnostic_test_results) {
        // Ordenar contenido
        this.student.diagnostic_test_results.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        this.evaluationDiagnostic = this.student.diagnostic_test_results.length !== 0 ? this.student.diagnostic_test_results[0] : {};
      }

    },
  },
  computed: {
    hasPendingPayments() {
      return this.student.pending_payments?.length > 0;
    },
    completeInfo() {
      const infoStatus = Object.values(this.ubicationSelected).every(
        (value) => value !== null
      );
      return infoStatus;
    },
    existEvaluation() {
      return Object.keys(this.evaluationDiagnostic).length !== 0 && this.evaluationDiagnostic?.suggested_level !== null;
    },
    ...mapGetters({
      api: "getApi",
      apiAdmision: "getAdmisionApi",
      apiAis: "getAisApi",
      apiCore: "getCoreApi",
    }),
  }
};
</script>

<style></style>
